class FormValidation {
    constructor() {
        this.formSuccess();
    }
    formSuccess() {
        const params = new URLSearchParams(window.location.search);
        const formParam = params.get('form');
        const successParam = params.get('success');

        if (formParam !== null && successParam) {
            let formEL = document.getElementById(formParam);
            let successMessage = formEL.parentNode.querySelector('.message-success');

            successMessage.classList.add('_show');
            setTimeout(()=> {
                successMessage.scrollIntoView();
            }, 500);
        }
    }
}

export default new FormValidation();
