class EmbedForms {
    constructor() {
        // Add button style classes to submit buttons
        document.querySelectorAll('.embed-form form').forEach(form => {
            // Set redirect URL
            const formRedirectInput = form.querySelector('[name="retURL"]');
            const formId = form.getAttribute('id');

            let redirect = window.location.href.split('?')[0] + '?success=1';

            if (formRedirectInput) {
                if (formId) {
                    // Update the value of the input
                    redirect = redirect + '&form=' + formId;
                }

                formRedirectInput.value = redirect;
            }
            else {
                // Create element for retUrl
                const nodeInput = document.createElement('input');
                nodeInput.setAttribute('name', 'retURL');

                if (formId) {
                    // Update the value of the input
                    redirect = redirect + '&form=' + formId;
                }

                nodeInput.value = redirect;

                // Add to form
                nodeInput.appendChild(form);
            }

            // Style submit button
            const submitBtnEls = form.querySelectorAll('[type="submit"]');
            submitBtnEls.forEach(btn => {
                btn.classList.add("btn", "_teal");
            });
        });
    }
}

export default new EmbedForms();
