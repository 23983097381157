/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// Imports
import EmbedForms from '@js/modules/forms/EmbedForms';
import FormValidation from '@js/modules/forms/FormValidation';
import PhoneCodes from '@js/modules/forms/PhoneCodes';

// From JS
window.SUSTAINABLESALONS_FORMS =
    window.SUSTAINABLESALONS_FORMS ||
    new (function SUSTAINABLESALONS_FORMS() {
        this.modules = {
            PhoneCodes: PhoneCodes,
        };
    })();
